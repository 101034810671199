import {
    request,
    METHOD
} from '@/utils/request'
import {
    addurl
} from '@/utils/util'
import {
    baseURL
} from '@/config/proxyconfig'

const requesturl = baseURL + '/api/book/'
const requesturlAD = baseURL + '/api/ad/'
//图书分类
export async function getBookTheme() {
    return request(addurl('getBookTheme', requesturl), METHOD.GET)
}
//查询新书
export async function getNewBook(params) {
    return request(addurl('getNewBook', requesturl) + '/' + params.pageNumber + '/' + params.pageSize,
        METHOD.GET)
}
//查询图书详情和作者介绍
export async function getBookDetail(doi) {
    return request(addurl('getBookDetail', requesturl) + '/' + doi,
        METHOD.GET)
}
//查询相关图书
export async function getCorrelationBook(params) {
    return request(addurl('getCorrelationBook', requesturl) + '/' + params.doi + '/' + params.pageSize + '/' + params.pageNumber,
        METHOD.GET)
}
//查询图书列表
export async function getBookList(params) {
    return request(addurl('getBookList', requesturl) + '/' + params.pageNumber +  '/' + params.pageSize +  '?classId=' + params.theme + '&year=' + params.year + '&query=' + params.query + '&order=' + params.order + '&sort=' + params.sort,
        METHOD.GET)
}
//查询年份
export async function getYear() {
    return request(addurl('getYear', requesturl),
        METHOD.GET)
}
//查询广告轮播图
export async function getAddList(params) {
    return request(addurl('getAddList', requesturlAD)  + '/' + params.pageNumber +  '/' + params.pageSize  + '?type=' + params.type ,
        METHOD.GET)
}


export default {
    getBookTheme,
}