<template>
  <div class="book-index">
    <div class="con-item con-column">
      <div class="container">
        <!--图书分类-->
        <!-- <div class="book-classify">
          <a-card title="全部图书分类详情" :bordered="false">
            <div class="item" v-for="data in bookTypeList" :key="data.id">
              <h3 class="book-classify-title" @click="goBookList(data.id, '')"><i></i>{{ data.themename }}</h3>
              <a-list
                :grid="{ gutter: 16, column: 2 }"
                :data-source="data.childrenList"
                :locale="{emptyText: '暂无数据'}"
              >
                <a-list-item slot="renderItem" slot-scope="item">
                  <a
                    class="overflow-more-one"
                    :title="item.themename"
                    @click="goBookList(data.id, item.id)"
                    >{{ item.themename }}</a
                  >
                </a-list-item>
              </a-list>
              <a-divider dashed />
            </div>
          </a-card>
        </div> -->
        <!--图书banner-->
        <div class="book-banner">
          <a-card :bordered="false">
            <a-carousel :autoplay="true" v-if="carouselList.length >0">
              <div v-for="(item, index) in carouselList" :key="index">
                <a :href="item.imgurl" target="_blank">
                  <div class="background-img-pic" :style="{backgroundImage:'url('+item.imgurlPic+')',backgroundSize:'100% 100%'}"></div>
                </a>
              </div>
            </a-carousel>
          </a-card>
        </div>
      </div>
    </div>
    <!--新书快讯-->
    <div class="con-item newbook">
      <div class="container">
        <div class="con-title con-title-3">
          <span>新书快讯</span>
        </div>
        <div class="newbook-list">
          <a-list :data-source="dataNewbookTop">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false" class="new-book">
                <img
                  @click="gobookDetails(item.sysFldDoi)"
                  slot="cover"
                  alt=""
                  :src="item.imgurl"
                />
                <span></span>
              </a-card>
            </a-list-item>
          </a-list>
          <a-list :data-source="dataNewbookBottom" v-if="dataNewbookBottom.length>0">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false" class="new-book">
                <img
                  @click="gobookDetails(item.sysFldDoi)"
                  slot="cover"
                  alt=""
                  :src="item.imgurl"
                />
                <span></span>
              </a-card>
            </a-list-item>
          </a-list>
          <!-- <a-button
            shape="circle"
            icon="left"
            class="arrow-l"
            @click="lessChange"
          />
          <a-button
            shape="circle"
            icon="right"
            class="arrow-r"
            @click="moreChange"
          /> -->
        </div>
      </div>
    </div>
    <!--重磅推荐-->
    <div class="con-item recbook">
      <div class="container">
        <div class="con-title con-title-3 con-title-white">
          <span>重磅推荐</span>
        </div>
        <div class="newbook-list">
          <a-list :data-source="dataRecbookTop">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false" class="new-book">
                <img
                  @click="gobookDetails(item.sysFldDoi)"
                  slot="cover"
                  alt=""
                  :src="item.imgurl"
                />
              </a-card>
            </a-list-item>
          </a-list>
          <a-list :data-source="dataRecbookBottom" v-if="dataRecbookBottom.length>0">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false" class="new-book">
                <img
                  @click="gobookDetails(item.sysFldDoi)"
                  slot="cover"
                  alt=""
                  :src="item.imgurl"
                />
              </a-card>
            </a-list-item>
          </a-list>
          <!-- <a-button
            shape="circle"
            icon="left"
            class="arrow-l"
            @click="recomBookLess"
          />
          <a-button
            shape="circle"
            icon="right"
            class="arrow-r"
            @click="recomBookAdd"
          /> -->
        </div>
      </div>
    </div>
    <!--重磅推荐-->
    <div class="con-item book-class">
      <div class="container">
        <div class="con-title con-title-3">
          <span>图书分类</span>
        </div>
        <ul class="class-list">
          <li class="class-item" v-for="(item, index) in bookTypeList" :key="index">
            <div class="item-header">
              <div class="header-icon">
                <img :src="require(`@/assets/images/book-class${ index < 7 ? index + 1 : 7 }.png`)" alt="">
              </div>
              <div class="header-title">
                <a @click="onGotoBookList(item)">{{ item.themename }}</a>
              </div>
            </div>
            <ul class="item-content">
              <li v-for="(el, i) in item.childrenList" :key="i">
                <a @click="onGotoBookList(el)">{{ el.themename }}</a>
              </li>
            </ul>
          </li>
          <li class="class-item class-item-nochild">
            <div class="item-header">
              <div class="header-icon">
                <img :src="require(`@/assets/images/book-class${ 7 }.png`)" alt="">
              </div>
              <div class="header-title" v-for="(item, index) in bookOtherTypeList" :key="index">
                <a @click="onGotoBookList(item)">{{ item.themename }}</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const orderTheme = (list) => {
  return list.sort((a, b) => a.ordernum - b.ordernum);
};

import * as api from "@/services/book.js";
import * as apiIndex from "@/services/index.js";

export default {
  name: "Index",
  components: {},
  data() {
    return {
      visible: false,
      currentPage: ["bookIndex"],
      dataNewbook: [],
      dataNewbookTop:[],
      dataNewbookBottom:[],
      dataRecbook: [],
      dataRecbookTop: [],
      dataRecbookBottom: [],
      bookTypeList: [],
      bookOtherTypeList: [],
      bookNum: 1,
      bookCount: 0,
      bookDoi: "",
      recommendNum: 1,
      recommendBookCount: 0,
      carouselList: [],
    };
  },
  created() {
    //获取图书分类
    this.getBookTheme();
    //查询新书
    this.getNewBook();
    //查询推荐图书
    this.getRecommendList();
    //查询广告轮播图
    this.getAddList();
  },
  methods: {
    getAddList() {
      api
        .getAddList({
          pageSize: 10,
          pageNumber: 1,
          type:'1'
        })
        .then((res) => {
          if (res.data.success) {
            let list = res.data.data.list || []
            this.carouselList = orderTheme(list)
            this.carouselList.map(function (item) {
              item.imgurlPic =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldFilepath;
            });
          } else {
            this.carouselList = [];
          }
        });
    },
    //去搜索
    goBookList(pid, id) {
      this.$router.push({
        name: "BookList",
        params: {
          parentType: pid,
          type: id,
        },
      });
    },
    //去图书详情
    gobookDetails(doi) {
      this.$router.push({
        name: "BookDetail",
        params: {
          doi: doi,
        },
      });
    },
    //图书推荐左按钮
    recomBookLess() {
      if (this.recommendNum === 1) {
        return;
      }
      this.recommendNum--;
      this.getRecommendbook(this.bookDoi);
    },
    //图书推荐右按钮
    recomBookAdd() {
      if (
        this.recommendNum ===
        (this.recommendBookCount % 5 === 0
          ? this.recommendBookCount / 5
          : Math.floor(this.recommendBookCount / 5) + 1)
      ) {
        this.recommendNum = 1;
        this.getRecommendbook(this.bookDoi);
        return;
      }
      this.recommendNum++;
      this.getRecommendbook(this.bookDoi);
    },
    //获取推荐栏目
    getRecommendList() {
      let vm = this;
      apiIndex.getRecommendList().then((res) => {
        if (res.data.success) {
          let list = res.data.data;
          list.map(function (data) {
            if (data.columnname === "图书展示-重磅图书推荐") {
              //查询推荐  首页活动中心
              vm.getRecommendbook(data.doi);
              vm.bookDoi = data.doi;
            }
          });
        }
      });
    },
    //获取推荐图书
    getRecommendbook(doi) {
      let obj = {
        pageSize: 10,
        pageNumber: this.recommendNum,
        columnId: doi,
      };
      apiIndex.getRecommendBook(obj).then((res) => {
        if (res.data.success) {
          //通知公告
          this.dataRecbook = res.data.data.list || [];
          this.recommendBookCount = res.data.data.count;
          this.dataRecbook.map(function (item) {
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
          });
          this.dataRecbookTop = this.dataRecbook.slice(0,5)
          this.dataRecbookBottom = this.dataRecbook.slice(5,10)
        }
      });
    },
    //图书左按钮
    lessChange() {
      if (this.bookNum === 1) {
        return;
      }
      this.bookNum--;
      this.getNewBook();
    },
    //图书右按钮
    moreChange() {
      if (
        this.bookNum ===
        (this.bookCount % 5 === 0
          ? this.bookCount / 5
          : Math.floor(this.bookCount / 5) + 1)
      ) {
        this.bookNum = 1;
        this.getNewBook();
        return;
      }
      this.bookNum++;
      this.getNewBook();
    },
    //获取图书分类
    getBookTheme() {
      api.getBookTheme().then((res) => {
        if (res.data.success) {
          let vm = this
          this.bookTypeList = []
          this.bookOtherTypeList = []
          let bookTypeList = res.data.data;
          bookTypeList.map(function (item) {
            if (item.childrenList) {
              item.childrenList = item.childrenList.slice(0, 4);
              vm.bookTypeList.push(item)
            }else{
              vm.bookOtherTypeList.push(item)
            }
          });
        } else {
          this.bookTypeList = [];
        }
      });
    },
    onGotoBookList(item) {
      let parentType = '', type = ''
      if(item.parentid === '0') {
        parentType = item.id
      }else{
        parentType = item.parentid
        type = item.id
      }
      this.$router.push({
        name: 'BookList',
        params: {
          parentType: parentType,
          type: type
        }
      })
    },
    //查询新书
    getNewBook() {
      let obj = {
        pageSize: 10,
        pageNumber: this.bookNum,
      };
      api.getNewBook(obj).then((res) => {
        if (res.data.success) {
          this.dataNewbook = res.data.data.list;
          this.bookCount = res.data.data.count;
          this.dataNewbook.map(function (item) {
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
            // item.imgurl = require('@/assets/images/book-img1.jpg')
          });
          this.dataNewbookTop = this.dataNewbook.slice(0,5)
          this.dataNewbookBottom = this.dataNewbook.slice(5,10)
        }
      });
    },
    onSearch(value) {
      console.log(value);
    },
  },
};
</script>
<style lang="less">
@import "./BookIndex.less";
</style>
