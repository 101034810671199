import {
    request,
    METHOD
} from '@/utils/request'
import {
    addurl
} from '@/utils/util'
import {
    baseURL
} from '@/config/proxyconfig'

const requesturl = baseURL + '/api/resourceList/'
const bookRequesturl = baseURL + '/api/book/'
const requesturlAD = baseURL + '/api/ad/'

//查询广告轮播图
export async function getAddList(params) {
    return request(addurl('getAddList', requesturlAD)  + '/' + params.pageNumber +  '/' + params.pageSize  + '?type=' + params.type ,
        METHOD.GET)
}

//获取推荐栏目
export async function getRecommendList() {
    return request(addurl('getRecommendList', requesturl), METHOD.GET)
}
//首页-新书快讯-力荐新书
export async function getRecommendNewBook(params) {
    return request(addurl('getRecommendNewBook', requesturl) + '?columnId=' + params.columnId, METHOD.GET)
}
//查询推荐新闻
export async function getRecommendNote(params) {
    return request(addurl('getRecommendNote', requesturl) + '/' + params.pageNumber + '/' + params.pageSize + '?classId=' + params.classId + '&columnId=' + params.columnId,
        METHOD.GET)
}
//查询推荐图书
export async function getRecommendBook(params) {
    return request(addurl('getRecommendBook', requesturl) + '/' + params.pageNumber + '/' + params.pageSize + '?columnId=' + params.columnId,
        METHOD.GET)
}
//查询新书
export async function getNewBook(params) {
    return request(addurl('getNewBook', bookRequesturl) + '/' + params.pageNumber + '/' + params.pageSize,
        METHOD.GET)
}


export default {
    getRecommendList,
}